import React from 'react';

import { useText } from 'common/hooks';

import {
  Header,
  Image,
  ImageWrapper,
  Paragraph,
  SubHeader,
  Wrapper,
  Link,
  Button,
  MagazineContainer,
  MagazineImage,
} from './elements';

export default function Kroniken() {
  const t = useText();

  return (
    <Wrapper>
      <Header>{'Krøniken'}</Header>
      <Paragraph>{t('kroniken.firstParagraph')}</Paragraph>
      <ImageWrapper>
        <Image src={'/images/kroniken-header.png'} />
      </ImageWrapper>
      <SubHeader>{t('kroniken.subHeader')}</SubHeader>
      <Paragraph>{t('kroniken.secondParagraph')}</Paragraph>
      <Button href="https://drive.google.com/drive/folders/10cJ19Ge7tPnEjZqYdgNxikZzpCpOZhSk">
        {t('kroniken.submitImages')}
      </Button>
      <SubHeader>{t('kroniken.oldEditions')}</SubHeader>
      {/* <Link>{t('kroniken.secondEdition')}</Link> */}
      <Paragraph>{t('kroniken.buy')}</Paragraph>
      <Paragraph>{t('kroniken.thirdParagraph')}</Paragraph>
      <MagazineContainer>
        <a
          href="https://heyzine.com/flip-book/d4903954ba.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <MagazineImage src="https://cdnc.heyzine.com/flip-book/cover/d4903954ba.jpg" />
        </a>
        <a
          href="https://heyzine.com/flip-book/cfd5e972e1.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <MagazineImage src="https://cdnc.heyzine.com/flip-book/cover/cfd5e972e1.jpg" />
        </a>
        <a
          href="https://heyzine.com/flip-book/eb01b6a367.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <MagazineImage src="https://cdnc.heyzine.com/flip-book/cover/eb01b6a367.jpg" />
        </a>
      </MagazineContainer>
    </Wrapper>
  );
}
