import firstParagraph from './firstParagraph';
import secondParagraph from './secondParagraph';
import thirdParagraph from './thirdParagraph';

export default {
  title: 'STUDENTENES KAMERAKLUBB',
  join: 'Bli medlem!',
  events: 'Kommende arrangementer:',
  moreEvents: 'Se alle arrangementer',
  galleries: 'Aktive gallerier',
  banner:
    'På grunn av et innbrudd bruker vi midlertidig et nøkkelregister for å få tilgang til kjelleren. Les mer om det her.',
  firstSection: {
    title: 'Velkommen!',
    text: firstParagraph,
  },
  secondSection: {
    title: 'Fasiliteter',
    text: secondParagraph,
  },
  thirdSection: {
    title: 'Aktiviteter',
    text: thirdParagraph,
  },
  message: {
    title: 'Send oss en melding',
    form: {
      name: {
        title: 'Navn',
        placeholder: 'Ditt navn',
      },
      email: {
        title: 'Epost',
        placeholder: 'Din epost',
      },
      message: {
        title: 'Melding',
      },
      submit: 'Send',
    },
  },
};
