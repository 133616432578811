import firstParagraph from './firstParagraph';
import secondParagraph from './secondParagraph';
import thirdParagraph from './thirdParagraph';

export default {
  title: 'Studentenes Kameraklubb',
  welcome: 'Welcome!',
  join: 'Join us!',
  events: 'Upcoming events:',
  moreEvents: 'See all events',
  galleries: 'Active galleries',
  banner:
    'Due to a break-in we are temporarily using a key-register to access the basement. Read more about it here.',
  firstSection: {
    title: 'Welcome!',
    text: firstParagraph,
  },
  secondSection: {
    title: 'Facilities',
    text: secondParagraph,
  },
  thirdSection: {
    title: 'Activities',
    text: thirdParagraph,
  },
  message: {
    title: 'Send us a message',
    form: {
      name: {
        title: 'Name',
        placeholder: 'Your name',
      },
      email: {
        title: 'Email',
        placeholder: 'Your email',
      },
      message: {
        title: 'Message',
      },
      submit: 'Send',
    },
  },
};
